import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  useMediaQuery, 
  useTheme 
} from '@mui/material';

function ResultsTable({ results }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <List component={Paper}>
        {results.map((row) => (
          <ListItem key={row.InventoryID} divider>
            <ListItemText
              primary={row.Name}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="text.primary">
                    Location: {row.Location}
                  </Typography>
                  <br />
                  <Typography component="span" variant="body2" color="text.secondary">
                    SubLocation: {row.SubLocation}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    );
  }

  // Existing table view for larger screens
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="search results table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>SubLocation</TableCell>
            {/* <TableCell>Primary Definition</TableCell> */}
            {/* <TableCell>Historical Markers</TableCell> */}
            {/* <TableCell>Is Memorabilia</TableCell> */}
            {/*<TableCell>Tags</TableCell>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((row) => (
            <TableRow key={row.InventoryID}>
              <TableCell>{row.Name}</TableCell>
              <TableCell>{row.Location}</TableCell>
              <TableCell>{row.SubLocation}</TableCell>
              {/* <TableCell>{row.PrimaryDefinition}</TableCell>
              <TableCell>{row.HistoricalMarkers}</TableCell>
              <TableCell>{row.IsMemorabilia}</TableCell>
              <TableCell>{row.Tags}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ResultsTable;