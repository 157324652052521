import React, { useState } from 'react';
import { TextField, Button, Box, useMediaQuery, useTheme } from '@mui/material';

function SearchBar({ onSearch }) {
  const [query, setQuery] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(query);
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      sx={{ 
        my: { xs: 2, sm: 4 },
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        gap: 2
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        label="Search Inventory"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        sx={{ flexGrow: 1 }}
      />
      <Button 
        type="submit" 
        variant="contained" 
        color="primary" 
        sx={{ 
          height: 56,
          width: isMobile ? '100%' : 'auto'
        }}
      >
        Search
      </Button>
    </Box>
  );
}

export default SearchBar;