import React, { useState } from 'react';
import { Container, CssBaseline, ThemeProvider, Button, Box, Snackbar } from '@mui/material';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import SearchBar from './components/SearchBar';
import ResultsTable from './components/ResultsTable';
import AddItemForm from './components/AddItemForm';
import AuditScreen from './components/AuditScreen';
import OneTimeScreen from './components/OneTimeScreen';
import LoginPage from './components/LoginPage';
import LogoutButton from './components/LogoutButton';
import InventoryDataManagementScreen from './components/InventoryDataManagementScreen';
import { useApi } from './api';
import theme from './theme';

function AppContent() {
  const [searchResults, setSearchResults] = useState([]);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [isAuditing, setIsAuditing] = useState(false);
  const [isOneTime, setIsOneTime] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { isAuthenticated, isLoading } = useAuth0();
  const api = useApi();

  const handleSearch = async (query) => {
    try {
      const results = await api.searchInventory(query);
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching inventory:', error);
      setSnackbarMessage('Error searching inventory. Please try again.');
      setSnackbarOpen(true);
    }
  };

  const handleAddItem = async (itemData) => {
    try {
      const newItem = await api.addInventoryItem(itemData);
      console.log('New item added:', newItem);
      setSnackbarMessage('Item added successfully!');
      setSnackbarOpen(true);
      handleSearch("");
    } catch (error) {
      console.error('Error adding item:', error);
      setSnackbarMessage(`Failed to add item: ${error.message}`);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
      <LogoutButton />
      {!isAddingItem && !isAuditing && !isOneTime ? (
        <>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
            <SearchBar onSearch={handleSearch} />
            <Box sx={{ display: 'flex', mt: { xs: 2, sm: 0 } }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => setIsAuditing(true)}
                sx={{ mr: 2 }}
              >
                Audit
              </Button>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => setIsAddingItem(true)}
                sx={{ mr: 2 }}
              >
                + Add Item(s)
              </Button>
              {/* <Button 
                variant="contained" 
                color="info" 
                onClick={() => setIsOneTime(true)}
                sx={{ mr: 2 }}
              >
                OneTime
              </Button> */}
              <Button 
                variant="contained" 
                color="success" 
                component={Link}
                to="/inventory-data-management"
              >
                Manage Data
              </Button>
            </Box>
          </Box>
          <ResultsTable results={searchResults} />
        </>
      ) : isAddingItem ? (
        <AddItemForm 
          onSubmit={handleAddItem} 
          onCancel={() => setIsAddingItem(false)} 
        />
      ) : isAuditing ? (
        <AuditScreen onCancel={() => setIsAuditing(false)} />
      ) : isOneTime ? (
        <OneTimeScreen onClose={() => setIsOneTime(false)} />
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Container>
  );
}

function App() {
  return (
    <Auth0Provider
      domain="dev-l8hbhu2ubpb7ry34.us.auth0.com"
      clientId="VhEk1ylTKgFYn8VsaFEl1unBml5eRA5a"
      redirectUri={window.location.origin}
      audience="https://inventory-api"
      scope="read:items create:items update:items delete:items"
      onRedirectCallback={(appState) => {
        window.history.replaceState(
          {},
          document.title,
          appState?.returnTo || window.location.pathname
        );
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/inventory-data-management" component={InventoryDataManagementScreen} />
            <Route path="/" component={AppContent} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Auth0Provider>
  );
}

export default App;