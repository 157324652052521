import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-l8hbhu2ubpb7ry34.us.auth0.com"
      clientId="VhEk1ylTKgFYn8VsaFEl1unBml5eRA5a"
      redirectUri={window.location.origin}
      audience="https://inventory-api"
      scope="read:items create:items update:items delete:items"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();