import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import LoginButton from './LoginButton';

const LoginPage = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src="/logo.png" alt="RC Radio Stock Logo" style={{ width: 100, height: 100, marginBottom: 2 }} />
        <Typography component="h1" variant="h5" gutterBottom>
          RC Radio Stock Inventory
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          Welcome to the RCRadioStock Inventory Management System. This application helps you efficiently manage and track inventory for your beloved hobbies like Aeromodelling and HAM Radio.
        </Typography>
        <Box mt={2}>
          <LoginButton />
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;