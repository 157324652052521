import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  useMediaQuery, 
  useTheme,
  Grid 
} from '@mui/material';

const initialState = {
  Name: '',
  Location: '',
  SubLocation: '',
  PrimaryDefinition: '',
  MaterialGroup: '',
  HobbyGroup: '',
  HistoricalMarkers: '',
  IsMemorabilia: '',
  Tags: '',
};

function AddItemForm({ onSubmit, onCancel }) {
  const [formData, setFormData] = useState(initialState);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData(initialState);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Add New Item</Typography>
      <Grid container spacing={isMobile ? 1 : 2}>
        {Object.keys(initialState).map((field) => (
          <Grid item xs={12} sm={6} key={field}>
            <TextField
              fullWidth
              margin="normal"
              label={field.replace(/([A-Z])/g, ' $1').trim()}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              required
              size={isMobile ? "small" : "medium"}
            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
        <Button 
          onClick={onCancel} 
          variant="outlined"
          fullWidth={isMobile}
          sx={{ mb: isMobile ? 1 : 0 }}
        >
          Cancel
        </Button>
        <Button 
          type="submit" 
          variant="contained" 
          color="primary"
          fullWidth={isMobile}
        >
          Add Item
        </Button>
      </Box>
    </Box>
  );
}

export default AddItemForm;