import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const API_URL = process.env.REACT_APP_API_URL || 'https://inventoryappbackend-bz3x.onrender.com';

export const useApi = () => {
  const { getIdTokenClaims, user } = useAuth0();

  const authAxios = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  authAxios.interceptors.request.use(async (config) => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
      config.headers.Authorization = `Bearer ${idToken}`;
      if (user && user.sub) {
        config.headers['User-ID'] = user.sub;
      }
    } catch (error) {
      console.error('Error getting ID token:', error);
    }
    return config;
  });

  const searchInventory = async (query) => {
    try {
      console.log('Sending search request to:', `${API_URL}/audit/search/`);
      console.log('Search query:', query);
      const response = await authAxios.get('/audit/search/', { 
        params: { query } 
      });
      console.log('Search response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error searching inventory:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      throw error;
    }
  };

  const addInventoryItem = async (itemData) => {
    try {
      console.log('Sending item data:', itemData);
      const response = await authAxios.post('/items/', itemData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error adding inventory item:', error);
      if (error.response) {
        console.error('Response status:', error.response.status);
        console.error('Response data:', error.response.data);
        throw new Error(error.response.data.detail || 'Failed to add item');
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('Network error: No response received');
      } else {
        console.error('Error setting up request as in:', error.message);
        throw new Error('Error setting up request');
      }
    }
  };
  
  const getAllItems = async () => {
    try {
      const response = await authAxios.get('/all-items');
      return response.data;
    } catch (error) {
      console.error('Error fetching all items:', error);
      throw error;
    }
  };
  
  const updateUserId = async (itemId, userId) => {
    try {
      const response = await authAxios.put(`/update-user-id/${itemId}`, { user_id: userId });
      return response.data;
    } catch (error) {
      console.error('Error updating user ID:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Error(`Server error: ${error.response.data.detail || error.response.statusText}`);
      } else if (error.request) {
        // The request was made but no response was received
        throw new Error('No response received from server');
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error('Error setting up the request');
      }
    }
  };

  const updateInventoryItem = async (itemId, itemData) => {
    try {
      console.log('Updating item:', itemId, itemData);
      const response = await authAxios.put(`/audit/update/${itemId}`, itemData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Update response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating inventory item:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
      throw error;
    }
  };

  const exportItems = async () => {
    try {
      console.log('Initiating export request');
      const response = await authAxios.get('/export-items', { responseType: 'blob' });
      console.log('Export response received', response);
      return response;
    } catch (error) {
      console.error('Error in exportItems:', error);
      throw error;
    }
  };

  const importItems = async (formData) => {
    try {
      console.log('Initiating import request');
      const response = await authAxios.post('/import-items', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('Import response received', response);
      return response;
    } catch (error) {
      console.error('Error in importItems:', error);
      throw error;
    }
  };

  // const getItemsWithoutUserId = async () => {
  //   try {
  //     const response = await authAxios.get('/items-without-userid');
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error fetching items without user ID:', error);
  //     throw error;
  //   }
  // };

  return {
    searchInventory,
    addInventoryItem,
    updateInventoryItem,
    getAllItems,
    updateUserId,
    // getItemsWithoutUserId,
    exportItems,
    importItems
  };
};