import React, { useState } from 'react';
import { Button, Typography, Box, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useApi } from '../api';



const InventoryDataManagementScreen = () => {
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const api = useApi();

  const handleExport = async () => {
    console.log('Export button clicked');
    setIsExporting(true);
    try {
      console.log('Calling api.exportItems()');
      const response = await api.exportItems();
      console.log('Received response from api.exportItems()', response);
      
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'inventory_items.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log('CSV file should have been downloaded');
      setSnackbar({ open: true, message: 'Export successful!', severity: 'success' });
    } catch (error) {
      console.error('Export failed:', error);
      setSnackbar({ open: true, message: `Export failed: ${error.message}`, severity: 'error' });
    } finally {
      setIsExporting(false);
    }
  };

  const handleImport = async () => {
    if (!importFile) return;
    console.log('Import button clicked');
    setIsImporting(true);
    try {
      console.log('Preparing form data for import');
      const formData = new FormData();
      formData.append('file', importFile);
      console.log('Calling api.importItems()');
      await api.importItems(formData);
      console.log('Import successful');
      setSnackbar({ open: true, message: 'Import successful!', severity: 'success' });
    } catch (error) {
      console.error('Import failed:', error);
      setSnackbar({ open: true, message: `Import failed: ${error.message}`, severity: 'error' });
    } finally {
      setIsImporting(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('File selected:', file ? file.name : 'No file selected');
    setImportFile(file);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Inventory Data Management
      </Typography>
      <Box sx={{ my: 2 }}>
        <Button 
          variant="contained" 
          onClick={handleExport} 
          disabled={isExporting}
          sx={{ mr: 2 }}
        >
          {isExporting ? <CircularProgress size={24} /> : 'Export Inventory Data'}
        </Button>
      </Box>
      <Box sx={{ my: 2 }}>
        <input
          accept=".csv"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span" sx={{ mr: 2 }}>
            Choose File
          </Button>
        </label>
        {importFile && <Typography>{importFile.name}</Typography>}
      </Box>
      <Box sx={{ my: 2 }}>
        <Button 
          variant="contained" 
          onClick={handleImport} 
          disabled={isImporting || !importFile}
        >
          {isImporting ? <CircularProgress size={24} /> : 'Import Inventory Data'}
        </Button>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InventoryDataManagementScreen;