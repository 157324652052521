import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({ 
      returnTo: window.location.origin + '/login',
      clientId: 'VhEk1ylTKgFYn8VsaFEl1unBml5eRA5a'
    });
  };

  return (
    <Button 
      variant="outlined" 
      color="primary" 
      onClick={handleLogout}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;