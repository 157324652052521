import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@mui/material';
import { useApi } from '../api';

function AuditScreen({ onCancel }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [error, setError] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const api = useApi();

  const handleSearch = async () => {
    console.log('Searching for:', searchTerm);
    setError(null);
    try {
      const results = await api.searchInventory(searchTerm);
      console.log('Search results:', results);
      setSearchResults(results);
    } catch (error) {
      console.error('Error during search:', error);
      setError('An error occurred while searching. Please try again.');
      setSearchResults([]);
    }
  };

  const handleEdit = (item) => {
    setEditingItem({ ...item });
    setIsEditDialogOpen(true);
  };

  const handleSave = async () => {
    try {
      const updatedItem = await api.updateInventoryItem(editingItem.id, editingItem);
      console.log('Updated item:', updatedItem);
      setEditingItem(null);
      setIsEditDialogOpen(false);
      handleSearch(); // Refresh results
    } catch (error) {
      console.error('Error updating item:', error);
      setError('An error occurred while updating the item. Please try again.');
    }
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setEditingItem(null);
  };

  const handleFieldChange = (field, value) => {
    setEditingItem({ ...editingItem, [field]: value });
  };

  const editableFields = ['Name', 'Location', 'SubLocation', 'PrimaryDefinition', 'MaterialGroup', 'HobbyGroup', 'HistoricalMarkers', 'IsMemorabilia', 'Tags'];

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>Audit Screen</Typography>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Enter search term (name, location, etc.)"
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={handleSearch}>Search</Button>
      </Box>
      <Button variant="outlined" onClick={onCancel} sx={{ mb: 2 }}>Cancel</Button>
      
      {error && <Typography color="error">{error}</Typography>}

      {searchResults.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>SubLocation</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.Name}</TableCell>
                  <TableCell>{item.Location}</TableCell>
                  <TableCell>{item.SubLocation}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleEdit(item)}>Edit</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No results found</Typography>
      )}

      <Dialog open={isEditDialogOpen} onClose={handleCloseEditDialog} maxWidth="md" fullWidth>
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {editingItem && editableFields.map((field) => (
              <Grid item xs={12} sm={6} key={field}>
                <TextField
                  fullWidth
                  label={field}
                  value={editingItem[field] || ''}
                  onChange={(e) => handleFieldChange(field, e.target.value)}
                  margin="normal"
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AuditScreen;